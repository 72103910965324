"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.initCarousels = exports.Carousel = void 0;
var VisibilityObserver_1 = require("./VisibilityObserver");
var Slide;
(function (Slide) {
    Slide["Next"] = "NEXT";
    Slide["Previous"] = "PREVIOUS";
})(Slide || (Slide = {}));
;
var Carousel = /** @class */ (function (_super) {
    __extends(Carousel, _super);
    function Carousel(slider, animationDuration, autoSlidingDuration) {
        if (animationDuration === void 0) { animationDuration = 750; }
        if (autoSlidingDuration === void 0) { autoSlidingDuration = 5000; }
        var _this = _super.call(this) || this;
        /** Animation duration in ms, same in CSS transition */
        _this.animationDuration = 750;
        /** Auto Sliding timeout in ms */
        _this.autoSlidingDuration = 5000;
        _this.currentIndex = 0;
        _this.lastIndex = 0;
        _this.itemsCount = 1;
        _this.slidingDirection = Slide.Next;
        _this.isAnimating = false;
        _this.doesAutoSliding = false; // Auto Sliding state
        _this.didAutoSliding = false; // Auto Sliding state before page is hidden
        _this.stop = function () {
            _this.stopAutoSliding();
        };
        _this.slider = slider;
        _this.slider.ref = _this;
        _this.animationDuration = animationDuration;
        _this.autoSlidingDuration = autoSlidingDuration;
        _this.items = slider.querySelectorAll('.js-carousel-list > div');
        _this.indicators = slider.querySelectorAll('.js-carousel-indicators > .carousel__indicator');
        _this.itemsCount = _this.items.length;
        _this.initDom();
        _this.initListeners();
        return _this;
    }
    Carousel.prototype.initDom = function () {
        if (this.itemsCount <= 1) {
            if (this.slider.querySelector('.js-carousel-next-slide')) {
                this.slider.querySelector('.js-carousel-next-slide').style.display = 'none';
            }
            if (this.slider.querySelector('.js-carousel-previous-slide')) {
                this.slider.querySelector('.js-carousel-previous-slide').style.display = 'none';
            }
            if (this.slider.querySelector('.js-carousel-play')) {
                this.slider.querySelector('.js-carousel-play').style.display = 'none';
            }
            if (this.indicators.length) {
                this.slider.querySelector('.js-carousel-indicators').style.display = 'none';
            }
        }
        else if (this.itemsCount > 1) {
            if (this.slider.classList.contains('js-carousel--auto-slide') && !document.getElementsByTagName('html')[0].classList.contains('edit-mode')) {
                this.toggleAutoSliding();
            }
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].style.transition = "transform ".concat(this.animationDuration / 1000, "s ease-out");
            }
        }
        if (this.itemsCount == 0)
            return;
        this.items[0].classList.add('carousel__item--active');
        if (this.indicators.length) {
            this.indicators[0].classList.add('carousel__indicator--active');
        }
    };
    Carousel.prototype.initListeners = function () {
        var _this = this;
        if (this.slider.querySelector('.js-carousel-next-slide')) {
            this.slider.querySelector('.js-carousel-next-slide').addEventListener('click', function (e) {
                e.preventDefault();
                _this.stopAutoSliding();
                _this.triggerTransition(Slide.Next);
            });
        }
        if (this.slider.querySelector('.js-carousel-previous-slide')) {
            this.slider.querySelector('.js-carousel-previous-slide').addEventListener('click', function (e) {
                e.preventDefault();
                _this.stopAutoSliding();
                _this.triggerTransition(Slide.Previous);
            });
        }
        if (this.indicators.length) {
            var _loop_1 = function (i) {
                this_1.indicators[i].addEventListener('click', function (e) {
                    e.preventDefault();
                    _this.stopAutoSliding();
                    _this.triggerTransition(i);
                });
            };
            var this_1 = this;
            for (var i = 0; i < this.indicators.length; i++) {
                _loop_1(i);
            }
        }
        if (this.slider.querySelector('.js-carousel-play')) {
            this.slider.querySelector('.js-carousel-play').addEventListener('click', function (e) {
                e.preventDefault();
                _this.toggleAutoSliding();
            });
        }
        var touchStartX = 0;
        var dragging = false;
        this.slider.addEventListener('touchstart', function (e) {
            touchStartX = e.touches[0].clientX;
            dragging = true;
        }, { passive: true });
        // Swipe left/right
        this.slider.addEventListener('touchmove', function (e) {
            if (!dragging)
                return;
            var touchMove = e.changedTouches[0].clientX;
            var touchDiff = Math.abs(touchStartX - touchMove) > 80;
            if (touchStartX > touchMove && touchDiff) {
                _this.stopAutoSliding();
                _this.triggerTransition(Slide.Next);
                dragging = false;
            }
            else if (touchDiff) {
                _this.stopAutoSliding();
                _this.triggerTransition(Slide.Previous);
                dragging = false;
            }
        }, { passive: true });
        // Prevent vertical scrolling when swiping left/right
        this.slider.addEventListener('touchmove', function (e) {
            if (!dragging)
                return;
            var x = e.touches[0].clientX - touchStartX;
            if (Math.abs(x) > 10) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }, { passive: false });
    };
    Carousel.prototype.triggerTransition = function (slide) {
        if (this.currentIndex == slide)
            return;
        if (this.itemsCount == 1)
            return;
        if (this.isAnimating)
            return;
        this.setSlide(slide);
        this.slide();
        this.updateIndicator();
    };
    Carousel.prototype.setSlide = function (slide) {
        this.lastIndex = this.currentIndex;
        // Index of slide
        if (typeof slide === 'number') {
            this.currentIndex = slide;
            if (slide > this.lastIndex) {
                this.slidingDirection = Slide.Next;
            }
            else {
                this.slidingDirection = Slide.Previous;
            }
            return;
        }
        // Next or previous slide
        if (slide == Slide.Previous) {
            if (this.currentIndex == 0) {
                this.currentIndex = this.itemsCount - 1;
            }
            else {
                this.currentIndex--;
            }
            this.slidingDirection = Slide.Previous;
        }
        else {
            if (this.currentIndex == (this.itemsCount - 1)) {
                this.currentIndex = 0;
            }
            else {
                this.currentIndex++;
            }
            this.slidingDirection = Slide.Next;
        }
        return;
    };
    Carousel.prototype.setSlideStartCallback = function (slideStartCallback) {
        this.slideStartCallback = slideStartCallback;
    };
    Carousel.prototype.setSlideEndCallback = function (slideEndCallback) {
        this.slideEndCallback = slideEndCallback;
    };
    /**
     * Slide animation
     */
    Carousel.prototype.slide = function () {
        var _this = this;
        var slideOutClass = 'carousel__item--slide-out-left';
        var slideInClass = 'carousel__item--slide-in-right';
        if (this.slidingDirection == Slide.Previous) {
            slideOutClass = 'carousel__item--slide-out-right';
            slideInClass = 'carousel__item--slide-in-left';
        }
        this.isAnimating = true;
        if (this.slideStartCallback)
            this.slideStartCallback();
        this.items[this.lastIndex].classList.add(slideOutClass);
        this.items[this.currentIndex].classList.add('carousel__item--active');
        this.items[this.currentIndex].classList.add(slideInClass);
        // Trigger layout for "slide-in" classes
        window.getComputedStyle(this.items[this.currentIndex]).transform;
        this.items[this.currentIndex].classList.remove(slideInClass);
        this.items[this.currentIndex].classList.add('carousel__item--is-sliding');
        this.items[this.currentIndex].classList.add('carousel__item--active');
        // Hold values for timeout
        var lastIndex = this.lastIndex;
        var currentIndex = this.currentIndex;
        window.setTimeout(function () {
            _this.items[lastIndex].classList.remove('carousel__item--active');
            _this.items[lastIndex].classList.remove(slideOutClass);
            _this.items[currentIndex].classList.remove('carousel__item--is-sliding');
            _this.isAnimating = false;
            if (_this.slideEndCallback)
                _this.slideEndCallback();
        }, this.animationDuration);
    };
    /**
     * Transition without animation
     */
    Carousel.prototype.transit = function (slide) {
        this.items[this.currentIndex].classList.add('carousel__item--active');
        this.items[this.lastIndex].classList.remove('carousel__item--active');
    };
    Carousel.prototype.updateIndicator = function () {
        if (!this.indicators.length)
            return;
        this.indicators[this.lastIndex].classList.remove('carousel__indicator--active');
        this.indicators[this.currentIndex].classList.add('carousel__indicator--active');
    };
    Carousel.prototype.toggleAutoSliding = function () {
        if (this.isAnimating)
            return;
        if (this.doesAutoSliding) {
            this.stopAutoSliding();
        }
        else {
            this.startAutoSliding();
        }
    };
    Carousel.prototype.startAutoSliding = function () {
        var _this = this;
        this.doesAutoSliding = true;
        this.slider.classList.add('carousel--sliding');
        this.slider.classList.add('js-carousel--auto-slide');
        var carouselPlayButton = this.slider.querySelector('.js-carousel-play');
        if (carouselPlayButton)
            carouselPlayButton.setAttribute('aria-pressed', this.doesAutoSliding);
        this.autoSlidingTimer = window.setInterval(function () {
            _this.triggerTransition(Slide.Next);
        }, this.autoSlidingDuration);
    };
    Carousel.prototype.stopAutoSliding = function () {
        this.doesAutoSliding = false;
        this.slider.classList.remove('carousel--sliding');
        this.slider.classList.remove('js-carousel--auto-slide');
        var carouselPlayButton = this.slider.querySelector('.js-carousel-play');
        if (carouselPlayButton)
            carouselPlayButton.setAttribute('aria-pressed', this.doesAutoSliding);
        window.clearTimeout(this.autoSlidingTimer);
    };
    Carousel.prototype.handleHiddenState = function () {
        this.didAutoSliding = this.doesAutoSliding;
        this.stopAutoSliding();
    };
    Carousel.prototype.handleVisibleState = function () {
        // Only start auto sliding if auto sliding was active before the page was hidden
        if (this.didAutoSliding) {
            this.startAutoSliding();
        }
    };
    return Carousel;
}(VisibilityObserver_1.VisibilityObserver));
exports.Carousel = Carousel;
function initCarousels() {
    var carousels = document.querySelectorAll(':not(.edit-mode) .js-carousel');
    for (var i = 0; i < carousels.length; i++) {
        new Carousel(carousels[i]);
    }
}
exports.initCarousels = initCarousels;
